<template>
<div>
    <div v-if="isLoading" class="text-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div class="p-2">
            <div v-if="bookContent!=null">
                <div class="p-3" v-if="bookContent.contentHtml!=null">
                    <div class="text-end mb-3"><small>[ <a href="javascript:;" @click="ChangeLanguage()" class="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover" v-html="ChangeLanguageButtonText"></a> ]</small></div>
                    <h3>Okuma</h3>
                    <div v-html="bookContent.contentHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div> 
</template>
<script>
  export default {
    name: "TabLearnReading",
    props: {
      contents:{
        type:Array
      }
    },
    data() {
      return {
        contentLanguage:'en-EN',
        bookContent:null,
        isLoading:true,
      }
    },
    computed: {
        ChangeLanguageButtonText(){
            if(this.contentLanguage=='en-EN'){
                return 'Türkçe Göster <i class="bi bi-eye"></i>';
            }else{
                return 'Türkçe Gizle <i class="bi bi-eye-slash"></i>';
            }
       }
    },
    methods: {
        GetContent(){
            let contentType = 13;
            let contentHtml = '',contentTitle='',contentHtmlTr = '',contentImageUrl='';
            if(this.contents.length){
                let findBookContent = this.contents.filter(x=>x.contentType==contentType)[0];
                if(findBookContent){
                    contentHtml = findBookContent.contentHtml;
                    
                    let findBookContentTr = findBookContent.languages.filter(x=>x.languageCode=='tr-TR');
                    if(findBookContentTr){
                        contentHtmlTr = findBookContentTr[0].contentHtml;
                    }
                }
            }

            this.bookContent = {
                bookUrl: contentImageUrl,
                contentHtml : this.contentLanguage=='en-EN'?contentHtml:contentHtmlTr,
                contentTitle: contentTitle,
            }
        },
        ChangeLanguage(){
            this.contentLanguage = this.contentLanguage == 'en-EN'?'tr-TR':'en-EN';
            this.GetContent(this.contentType)
        }
    },
    mounted: function() {
    },
    watch:{
        contents(value){
            if(value){
                this.isLoading=false;
                this.GetContent();
            }
        }
    }
  };
  </script>
    
<style scoped>   
</style>