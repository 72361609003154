<template>
  <NavBar />
  <div class="container py-4">
    <div class="row align-items-start">
      <div class="col-lg-3">
        
        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body text-center">
            <img class="rounded-circle my-1" src="../assets/images/home/welcome.svg" style="width: 70%;"/>
            <p class="m-0">Lengedu' ya hoş geldin</p><div class="h5">{{ nameSurname }}!</div>
          </div>
        </div>

        <div class="card rounded-4 mb-4 border-0" v-if="dailyLessonBookUrl!=null && dailyLessonBookUrl!=''">
          <div class="card-body">
            <div class="row align-items-start">
              <div class="col align-middle fw-bold">
                <div class="mt-2">Günün Dersi</div>
              </div>
              <div class="col-4 text-end">
                <router-link :to="{path:'/courses/'+dailyLessonBookUrl}" class="btn btn-sm btn-primary"><i class="bi bi-arrow-right-short h4"></i></router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <div class="row align-items-start">
              <div class="col align-middle fw-bold">
                <div class="mt-2">Arkadaşlarını davet et</div>
              </div>
              <div class="col-4 text-end">
                <router-link to="/invite" class="btn btn-sm btn-primary"><i class="bi bi-arrow-right-short h4"></i></router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <div class="row align-items-start">
              <div class="col align-middle fw-bold">
                <div class="mt-2">Seviye testine katıl</div>
              </div>
              <div class="col-4 text-end">
                <router-link to="/tests" class="btn btn-sm btn-primary"><i class="bi bi-arrow-right-short h4"></i></router-link>
              </div>
            </div>
          </div>
        </div> -->

        

        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <div v-if="operatorInfo.loading" class="text-center">
              <div class="spinner-border">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div v-else>
              <div class="text-center mt-2 mb-2"><img src="../assets/images/home/support.svg" style="width: 100%;"></div>
              <div class="h4 text-center">Destek için her zaman buradayız.</div>
              <p class="card-text text-center">Ben eğitim koçunuz {{ operatorInfo.name }}</p><div class="text-center h3">{{ phoneFormat(operatorInfo.phone)}}</div>
            </div>
          </div>
        </div>

        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <div class="text-center mt-2 mb-2"><img src="../assets/images/home/randome-question.svg" style="width: 96%; margin:0 auto 0;"></div>
            <p>Rastgele soru uygulamasını keşfetmek ister misiniz?</p>
            <router-link to="/other-tools/random-question-generator" class="btn btn-sm btn-primary">İncele <i class="bi bi-arrow-right-short"></i></router-link>
          </div>
        </div>

        <!-- <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div> -->

      </div>
      <div class="col-lg-9">
       <LearningChart />
       <TrainingInfo @DailyLessonBookUrl="GetBookUrl($event)" />
       <h3 class="mb-4">Egzersizlere Gözat</h3>
       <div class="row">
          <div class="col-lg-4" v-for="e in exercises" :key="e">
            <div class="card border-0 mb-3">
              <img v-bind:src="require('../assets/images/excercises/'+e.image)" class="card-img-top" alt="..." style="width: 80%; margin: 0 auto 0;">
              <div class="card-body">
                <h5 class="card-title">{{e.label}}</h5>
                <p class="card-text text-light-emphasis">Makaleleri okuyun ve tartışın!</p>
                <router-link :to="'/excercises?q='+e.id" href="javascript:;" class="btn btn-sm btn-primary">Daha fazla bilgi <i class="bi bi-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <h5 class="card-title">Primary card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div>

        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <h5 class="card-title">Primary card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div>

        <div class="card rounded-4 mb-4 border-0">
          <div class="card-body">
            <h5 class="card-title">Primary card title</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import { mapActions } from "vuex";
//import { ErrorToast } from "@/common/notification.js";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import TrainingInfo from '@/components/Home/TrainingInfo.vue'
import LearningChart from '@/components/Home/LearningChart.vue'
import {GetRandomExercisesCategory} from '@/common/test-helper'
import store from "@/store";
export default {
  name: 'HomePage',
  data() {
    return {
      operatorInfo:{
        loading:false,
        name:'',
        phone:''
      },
      exercises:[],
      dailyLessonBookUrl:'',
    };
  },
  components: {
    NavBar,
    FooterInPage,
    TrainingInfo,
    LearningChart
  },
  computed:{
    nameSurname(){
      return store.getters['NameSurname'];
    }
  },
  methods:{
    ...mapActions(["GetOperatorInfo"]),
    
    phoneFormat(value){
      if(value){
        let phone = '+90 '+value.substr(0,3)+' '+value.substr(3,3)+' '+value.substr(-4);
        return phone;
      }
      return value
    },
    async getOperatorInfo(){
      this.operatorInfo.loading=true;
      //let toastId = LoadingToast();
      await this.GetOperatorInfo()
      .then((res)=>{
        this.operatorInfo.name=res.name;
        this.operatorInfo.phone=res.phoneNumber;
        this.operatorInfo.loading=false;
        //UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
      })
      .catch(()=>{
        this.operatorInfo.loading=false;
        //if(err.message){
        //  ErrorToast('GetOperatorInfo' + err.message);
        //}
      });
    },
    GetBookUrl(value){
      this.dailyLessonBookUrl = value;
    }
  },
  mounted: function() {
    this.getOperatorInfo();
    this.exercises = GetRandomExercisesCategory();
  }
}
</script>
<style>

</style>